<template>
  <div>
    <vs-prompt title="Motoristas" :active.sync="configuracaoPopUpMotoristas.exibirPopUpMotorista" v-if="configuracaoPopUpMotoristas.exibirPopUpMotorista"
      @cancel="fecharPopUpMotoristas" @accept="alterarMotorista" @close="fecharPopUpMotoristas"  :is-valid="isPopUpMotoristaValido"
      acceptText="OK" cancelText="Fechar">
      <PopUpMotoristas @selecionarMotorista="onSelecionarMotorista"          
         :idCliente="configuracaoPopUpMotoristas.idClienteSelecionado"
         :mensagemExplicacao="configuracaoPopUpMotoristas.mensagemExplicacao"> 
       </PopUpMotoristas>
    </vs-prompt>
    <vs-popup :title="`Mensalidade Acessório - ${this.veiculoSelecionado.placa}`" :active.sync="popUpAcessorio.exibir" v-if="popUpAcessorio.exibir">
        <label class="vs-input--label">Mensalidade</label> 
        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="popUpAcessorio.acessorio.valorIncrementoNaMensalidade" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }]"></vue-autonumeric>
        <vs-button class="mt-2" icon="save" color="primary" @click="gravarValorMensalidadeAcessorio()" :disabled="!valorValido(popUpAcessorio.acessorio.valorIncrementoNaMensalidade) || !habilitarEdicaoMensalidades"> Gravar</vs-button>
    </vs-popup>
    <vs-row class="p-0 mb-0">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"> 
            <vx-card class="mr-3 p-0">
                 <v-select  
                    label="nome"                                                                 
                    :options="listaClientes"  
                    placeholder="Selecione o cliente"
                    :filter="pesquisarCliente"
                    v-model="dadosAtivacao.clienteSelecionado"      
                    @input="selecionarCliente"                                  
                    class="w-full vs-input--label">
                    <template v-slot:option="option">                        
                        <span style='font-family: Verdana, sans-serif;'> {{ option.CNPJCPF }} </span> - {{ option.nome }}  - {{ option.razaoSocial }}
                    </template>
                </v-select>  
                
            </vx-card>
        </vs-col>
    </vs-row> 
    <vs-row v-if="exibirMensagemNenhumDadoEncontrado">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">           
            <vs-alert class="mt-4 mr-4" color="warning" title="Atenção">
                Não existem veículos ativos.
            </vs-alert>                          
        </vs-col>
    </vs-row>   
    <ExibirErrosConcept :errorsValidacao="errorsValidacao" ref="errosComponent"></ExibirErrosConcept>
    <div v-if="dadosAtivacao.clienteSelecionado">
        <form-wizard class="p-0 mb-0" ref="fwAtivacao" color="rgba(var(--vs-primary), 1)" errorColor="rgba(var(--vs-warning), 1)" :title="null" :subtitle="null" stepSize="xs" backButtonText="<" nextButtonText=">" finishButtonText="Gravar" @on-complete="ativar" @on-error="exibirMenssageErro">
            <tab-content :title="getTituloTab1" icon="feather icon-truck" :before-change="validarPasso1">
                <!-- tab 1 content -->
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <div class="flex mb-2 items-center" v-if="dadosAtivacao.clienteSelecionado">
                            <div class="w-full"> 
                                <label class="vs-input--label">Divisão:</label> 
                                <v-select                                                           
                                    label="nome"                                
                                    :options="listaDivisoesVeiculo" 
                                    v-model="divisaoSelecionada"                                        
                                    class="vs-input--label w-full" >
                                </v-select> 
                            </div>
                            <div class="w-full ml-2"> 
                                <label class="vs-input--label" >Termo:</label> 
                                <vx-input-group>     
                                    <input class="vs-input--input w-full" type="text"  style="min-width: 172px; line-height: 24px;" name="txtTermoPesquisa" id="txtTermoPesquisa" ref="txtTermoPesquisa"  v-on:keyup.13="pesquisarPorTermo($refs.txtTermoPesquisa.value)"/> 
                                    <template slot="append">
                                        <div class="append-text btn-addon">
                                            <vs-button color="dark" size="small" icon-pack="feather" icon="icon-search" @click="pesquisarPorTermo($refs.txtTermoPesquisa.value)"></vs-button>                                                
                                        </div>
                                    </template>
                                </vx-input-group>
                            </div>                    
                            <div class="flex mt-6 ml-2">                                
                                <h6 class="ml-2 mt-2 mr-2" v-if="listaVeiculos.length > 0 " style="white-space: nowrap;"> {{qtdRegistros}} / {{listaVeiculos.length}}</h6>
                            </div>
                        </div>
                        <vs-table ref="table" :data="filteredVeiculos" class="tabelaComScroll w-full mr-4" noDataText="..." maxItems="500" hoverFlat stripe v-model="veiculoSelecionado" @selected="selecionarVeiculo">                            
                            <template slot="thead">
                                <vs-th class="p-0">Veículo</vs-th>                    
                                <vs-th class="p-0">Placa</vs-th>                    
                                <vs-th class="p-0">Último Rastreador</vs-th>                    
                                <vs-th class="p-0">Ativação</vs-th>            
                            </template>
                            <template slot-scope="{data}">
                                <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr" style="border-bottom-style: solid; border-bottom-width: 1px; border-bottom-color: rgba(255, 255, 255, 0.2);">
                                    <vs-td  class="p-0"><small>{{tr.marca}} {{getModelo(tr)}} </small> </vs-td>
                                    <vs-td  class="p-0"><small class="font-bold" style='font-family: Verdana, sans-serif;'> {{tr.placa}} </small></vs-td>
                                    <vs-td  class="p-0">
                                            <small class="font-bold">{{tr.codigoModulo}}</small>  <small>{{tr.marcaModulo}}</small>
                                    </vs-td>                                    
                                    <vs-td class="p-0"><small style='font-family: Verdana, sans-serif;'>{{tr.dataAtivacao}}</small></vs-td>                                                           
                                </vs-tr>
                            </template>
                        </vs-table>
                    </div>
                </div>                
            </tab-content>
            <tab-content title="2. Dados do Veículo" icon="feather icon-tablet">
                <vs-tabs alignment="fixed" v-model="tabSelecionado" v-if="dadosAtivacao.veiculo.idVeiculo" v-on:click-tag="mudou" >
                    <vs-tab label="Dados" style="min-height: 250px">
                        <div class="vx-row">                        
                            <div class="vx-col w-1/3">
                                <vx-card>
                                    <label class="vs-input--label" >Tipo Veiculo</label> 
                                    <v-select                       
                                        label="label" 
                                        :reduce="tipoVeiculo => tipoVeiculo.data" 
                                        :options="listaTiposVeiculos" 
                                        v-model="dadosAtivacao.veiculo.tipoVeiculo"     
                                        style="height: 25px"
                                        class="style-chooser mb-4">
                                        <template slot="option" slot-scope="option">
                                            <img :src="`${publicPath}${option.fullImage}`">
                                        </template>
                                        <template #selected-option="{ fullImage }">
                                            <img :src="`${publicPath}${fullImage}`" style="max-height:25px">
                                        </template>
                                    </v-select> 
                                    
                                    <label class="vs-input--label" >Marca</label> 
                                    <v-select                       
                                        label="marca"        
                                        :reduce="m => m.marca"                
                                        :options="listaMarcaVeiculos" 
                                        v-model="dadosAtivacao.veiculo.marca"                      
                                        class="style-chooser w-full">
                                    </v-select> 
                                    
                                    <label class="vs-input--label" >Modelo</label> 
                                    <v-select                       
                                        label="modelo"        
                                        :reduce="m => m.modelo"                
                                        :options="listaModeloVeiculos" 
                                        v-model="dadosAtivacao.veiculo.modelo"                      
                                        class="style-chooser w-full">
                                    </v-select> 
                                    <div v-if="dadosAtivacao.veiculo.modelo === 'Outro'">
                                        <label class="vs-input--label">Outro Modelo</label>
                                        <vs-input class="w-full" v-model="dadosAtivacao.veiculo.modeloOutro" icon="label"/>
                                    </div> 
                                </vx-card>
                            </div>                               
                            <div class="vx-col w-1/3">
                                <vx-card>                            
                                    <div class="vx-row">
                                        <div class="vx-col w-1/2">    
                                            <label class="vs-input--label" >Placa</label> 
                                            <vs-input class="w-full" v-model="dadosAtivacao.veiculo.placa" v-mask="['AAA-#X##', 'AAA-#X##']" icon="calendar_view_day" />
                                        </div>
                                        <div class="vx-col w-1/2">    
                                            <label class="vs-input--label" >Cor</label> 
                                            <vs-input class="w-full" v-model="dadosAtivacao.veiculo.corVeiculo" icon="color_lens" />
                                        </div>
                                    </div>
                                    <div class="vx-row">
                                        <div class="vx-col w-1/2">    
                                            <label class="vs-input--label" >Ano Fabricação</label> 
                                            <vs-input class="w-full" v-model="dadosAtivacao.veiculo.anoFabricacao"  v-mask="['####']"  />
                                        </div>
                                        <div class="vx-col w-1/2">    
                                            <label class="vs-input--label" >Ano Modelo</label> 
                                            <vs-input class="w-full" v-model="dadosAtivacao.veiculo.anoModelo" v-mask="['####']" />
                                        </div>                                        
                                    </div>                            
                                    <div class="vx-row">
                                        <div class="vx-col w-1/2">    
                                            <label class="vs-input--label" >Label do Veículo</label> 
                                            <vs-input class="w-full" v-model="dadosAtivacao.veiculo.labelVeiculo"  />
                                        </div>
                                    </div>
                                </vx-card>
                            </div> 
                             <div class="vx-col w-1/3">
                                <vx-card>                                                               
                                    <div class="vx-row">
                                        <div class="vx-col w-1/2">    
                                            <label class="vs-input--label">KM/l</label>
                                            <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full" v-model="dadosAtivacao.veiculo.autonomia" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }]"></vue-autonumeric>
                                        </div>
                                        <div class="vx-col w-1/2">    
                                            <label class="vs-input--label">L/h</label>
                                            <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full" v-model="dadosAtivacao.veiculo.autonomiaPorHora" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }]"></vue-autonumeric>
                                        </div>                                       
                                    </div>
                                    <vx-input-group>
                                        <label class="vs-input--label" >Condutor</label> 
                                        <vs-input class="w-full" :readonly="true" v-model="dadosAtivacao.veiculo.condutor" icon-pack="feather" icon="icon-user" v-on:keyup="idNovoMotorista = nulll"/>
                                        <template slot="append">
                                            <div class="append-text btn-addon mt-5">
                                                <vs-button color="dark"  icon-pack="feather" icon="icon-search" @click="abrirPopUpMotoristas"></vs-button>                                                      
                                            </div>
                                        </template>
                                    </vx-input-group>
                                    <div class="vx-row">      
                                        <div class="vx-col w-full">    
                                            <vx-input-group>
                                                <label class="vs-input--label">Senha Individual</label>
                                                <vs-input class="w-full" v-model="dadosAtivacao.veiculo.senhaVeiculo"/>
                                                <template slot="append">
                                                    <div class="append-text btn-addon mt-5">
                                                        <vs-button color="warning"  icon-pack="feather" icon="icon-delete" @click="excluirSenhaIndividual" v-if="dadosAtivacao.veiculo.possuiSenhaIndividual"></vs-button>                                                      
                                                    </div>
                                                </template>
                                            </vx-input-group>
                                        </div>                   
                                    </div>         
                                </vx-card>
                            </div>                              
                        </div>
                    </vs-tab>
                    <vs-tab  label="Configuração">
                        <vx-card>
                            <div class="vx-row">
                                <div class="vx-col w-1/4"> 
                                    <label class="vs-input--label" >Alerta Sem Conexão</label> 
                                    <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="dadosAtivacao.veiculo.tempoSemConexaoParaAlerta" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'0' }]"></vue-autonumeric>
                                    <label class="vs-input--label text-warning" >(minutos) </label> 
                                </div>
                                <div class="vx-col w-1/4"> 
                                    <label class="vs-input--label" >Alerta Sem Conexão Ligado</label> 
                                    <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="dadosAtivacao.veiculo.tempoSemConexaoIgnicaoONParaAlerta" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'0' }]"></vue-autonumeric>
                                    <label class="vs-input--label text-warning" >(minutos) </label> 
                                </div>
                                <div class="vx-col w-1/4"> 
                                    <label class="vs-input--label" >Atualização / por minuto </label> 
                                    <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="dadosAtivacao.veiculo.taxaAtualizacao" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }]"></vue-autonumeric>
                                </div>
                                <div class="vx-col w-1/4"> 
                                    <label class="vs-input--label" >Atualização RPM/CAN / minuto</label> 
                                    <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="dadosAtivacao.veiculo.taxaAtualizacaoRPM" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }]"></vue-autonumeric>
                                </div>
                            </div>
                            <div class="vx-row">
                                <div class="vx-col w-1/4"> 
                                    <label class="vs-input--label">Origem Dados Odômetro</label> 
                                    <v-select                                                       
                                        :reduce="m => m.data"                
                                        :options="CONSTANTE_ORIGEM_DADOS" 
                                        v-model="dadosAtivacao.veiculo.origemDadosOdometro"                      
                                        class="w-full">
                                    </v-select> 
                                </div>
                                <div class="vx-col w-1/4"> 
                                    <label class="vs-input--label">Origem Dados Horímetro</label> 
                                    <v-select                                                       
                                        :reduce="m => m.data"                
                                        :options="CONSTANTE_ORIGEM_DADOS" 
                                        v-model="dadosAtivacao.veiculo.origemDadosHorimetro"                      
                                        class="w-full">
                                    </v-select> 
                                </div>
                                 <div class="vx-col w-1/4"> 
                                    <label class="vs-input--label" >Adicionar no odometro da CAN </label> 
                                    <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="dadosAtivacao.veiculo.correcaoOdometro" :options="[{ digitGroupSeparator: '', decimalCharacter: ',', decimalPlaces:'0' }]"></vue-autonumeric>
                                    <label class="vs-input--label" >Valor será multiplicado por 1000 </label> 
                                </div>
                                 <div class="vx-col w-1/4"> 
                                    <label class="vs-input--label" >Adicionar no horimetro da CAN</label> 
                                    <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="dadosAtivacao.veiculo.correcaoHorimetro" :options="[{ digitGroupSeparator: '', decimalCharacter: ',', decimalPlaces:'0' }]"></vue-autonumeric>
                                    <label class="vs-input--label" >Valor será multiplicado por 60 </label> 
                                </div>
                            </div>
                            <div class="vx-row mt-2">
                                    <div class="vx-col w-1/4">    
                                        <vs-checkbox v-model="dadosAtivacao.veiculo.possuiMonitriip" class="w-full"><small>Possui Monitriip</small></vs-checkbox>
                                    </div>
                                    <div class="vx-col w-1/4">    
                                        <vs-checkbox v-model="dadosAtivacao.veiculo.ativarEspelhamento" class="w-full"><small>Ativar Espelhamento ?</small></vs-checkbox>
                                    </div>
                                    <div class="vx-col w-1/4">    
                                        <vs-checkbox v-model="dadosAtivacao.veiculo.emManutencao" class="w-full"><small>Em manutenção ?</small></vs-checkbox>
                                    </div>
                                    <div class="vx-col w-1/4">    
                                        <vs-checkbox v-model="dadosAtivacao.veiculo.manterMotoristaVeiculoDesligado" class="w-full" v-if="dadosAtivacao.veiculo.possuiIdentificadorMotorista"><small>Manter motorista quando desligar ?</small></vs-checkbox>
                                    </div>
                            </div>
                             <div class="vx-row mt-2">
                                <div class="vx-col w-2/4">    
                                    <vx-tooltip :text="getTextoMonitoravelCentral">   
                                        <vs-checkbox v-model="dadosAtivacao.veiculo.possuiCentralMonitoramento" class="w-full" :disabled="desabilitarMonitoravelCentral"><small>Monitorável pela Central de Monitoramento</small></vs-checkbox>
                                    </vx-tooltip>
                                </div>
                                <div class="vx-col w-2/4">    
                                </div>
                            </div>
                        </vx-card>
                    </vs-tab>
                    <vs-tab  label="Contrato" style="min-height: 250px">
                        <div class="vx-row">                        
                            <div class="vx-col w-1/2">
                                <vx-card>
                                    <h5> Contrato</h5>     
                                    <div class="vx-row">
                                        <div class="vx-col w-2/3"> 
                                            <label class="vs-input--label" >Contrato</label> 
                                            <v-select                                                       
                                                :reduce="m => m.data"    
                                                :disabled="!habilitarEdicaoMensalidades"            
                                                :options="CONSTANTE_TIPO_CONTRATO_VEICULO" 
                                                v-model="dadosAtivacao.veiculo.tipoContratoVeiculo"                      
                                                class="w-full">
                                            </v-select> 
                                        </div>
                                        <div class="vx-col w-1/3"> 
                                            <label class="vs-input--label" >Prazo (meses)</label> 
                                            <vue-autonumeric :disabled="!habilitarEdicaoMensalidades" class="vs-inputx vs-input--input normal hasValue w-full"  v-model="dadosAtivacao.veiculo.prazoContratoVeiculoEmMeses" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'0' }]"></vue-autonumeric>
                                        </div>
                                    </div>                                    
                                </vx-card>
                            </div>
                            <div class="vx-col w-1/2">
                                <vx-card>
                                    <h5> Mensalidade </h5>                                        
                                    <label class="vs-input--label">Valor da Mensalidade R$ </label>
                                    <vue-autonumeric  :disabled="!habilitarEdicaoMensalidades" class="vs-inputx vs-input--input normal hasValue w-full" v-model="dadosAtivacao.veiculo.mensalidade" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }]"></vue-autonumeric>                                                           

                                    <label class="vs-input--label" v-if="dadosAtivacao.veiculo.marcaModuloBkp">Valor da Mensalidade Backup R$ </label>
                                    <vue-autonumeric  :disabled="!habilitarEdicaoMensalidades" v-if="dadosAtivacao.veiculo.marcaModuloBkp" class="vs-inputx vs-input--input normal hasValue w-full" v-model="dadosAtivacao.veiculo.mensalidadeBkp" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }]"></vue-autonumeric>                                                           
                                </vx-card>
                            </div>
                        </div>
                    </vs-tab>
                    <vs-tab  label="Contatos" style="min-height: 250px">
                        <vx-card>
                            
                            <h6 class="mt-4 mb-2"> Emails e Contatos de Alertas </h6>
                            <div class="vx-row">
                                    <div class="vx-col w-1/2 flex">    
                                        <vs-input class="w-1/2" placeholder="Nome contato 1" v-model="dadosAtivacao.veiculo.nomeContatoResponsavel1" maxlength="100"/>
                                        <vs-input class="w-1/2" placeholder="Email 1 para avisos" v-model="dadosAtivacao.veiculo.emailAvisoExcessoVelocidade" maxlength="45" />
                                        <vs-input placeholder="Telefone 1" v-model="dadosAtivacao.veiculo.celularlAvisoExcessoVelocidade" v-mask="['(##) #####-####', '(##) ####-####']"/>                                
                                    </div>
                                    <div class="vx-col w-1/2 flex">    
                                        <vs-input class="w-1/2" placeholder="Nome contato 2" v-model="dadosAtivacao.veiculo.nomeContatoResponsavel2" maxlength="100"/>
                                        <vs-input class="w-1/2" placeholder="Email 2 para avisos" v-model="dadosAtivacao.veiculo.emailAvisoSaidaCerca" maxlength="45" />
                                        <vs-input placeholder="Telefone 2" v-model="dadosAtivacao.veiculo.celularlAvisoSaidaCerca" v-mask="['(##) #####-####', '(##) ####-####']"/>                                
                                    </div>
                            </div>
                           <h6 class="mt-4 mb-2"> Contatos para Emergência/Pânico </h6>
                            <div class="vx-row mt-2">
                                    <div class="vx-col w-1/2">
                                        <label class="vs-input--label">Procedimento de Tratativa de Alertas </label>
                                        <vs-textarea v-model="dadosAtivacao.veiculo.procedimentoPanico" counter="1000" />
                                    </div>
                                    <div class="vx-col w-1/2">
                                        <div class="vx-row">
                                            <div class="vx-col w-full flex">
                                                <vs-input class="w-full" placeholder="Nome contato 1 pânico" v-model="dadosAtivacao.veiculo.nome1ContatoPanico" maxlength="100"/>
                                                <vs-input placeholder="Senha" v-model="dadosAtivacao.veiculo.senha1ContatoPanico" />
                                                <vs-input class="w-full" placeholder="Telefone" v-model="dadosAtivacao.veiculo.telefone1ContatoPanico" v-mask="['(##) #####-####', '(##) ####-####']"/>
                                            </div>
                                        </div>
                                        <div class="vx-row mt-1">
                                            <div class="vx-col w-full flex">
                                                <vs-input class="w-full" placeholder="Nome contato 2 pânico" v-model="dadosAtivacao.veiculo.nome2ContatoPanico" maxlength="100"/>
                                                <vs-input placeholder="Senha" v-model="dadosAtivacao.veiculo.senha2ContatoPanico" />
                                                <vs-input class="w-full" placeholder="Telefone" v-model="dadosAtivacao.veiculo.telefone2ContatoPanico" v-mask="['(##) #####-####', '(##) ####-####']"/>
                                            </div>
                                        </div>
                                        <div class="vx-row mt-1">
                                            <div class="vx-col flex w-full">
                                                <vs-input class="w-full" placeholder="Nome contato 3 pânico" v-model="dadosAtivacao.veiculo.nome3ContatoPanico" maxlength="100"/>
                                                <vs-input placeholder="Senha" v-model="dadosAtivacao.veiculo.senha3ContatoPanico" />
                                                <vs-input class="w-full" placeholder="Telefone" v-model="dadosAtivacao.veiculo.telefone3ContatoPanico" v-mask="['(##) #####-####', '(##) ####-####']"/>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </vx-card>
                    </vs-tab>
                    <vs-tab label="Rastreador" style="min-height: 250px">
                        <div class="flex"> 
                            <vx-card>
                                    <h5> Módulo </h5>
                                    <div class="vx-row">
                                        <div class="vx-col w-1/2"> 
                                            <label class="vs-input--label ml-1">Marca</label>
                                            <vs-input v-model="dadosAtivacao.veiculo.marcaModulo" class="w-full ml-1" readonly="true"/>
                                        </div>                                
                                        <div class="vx-col w-1/2"> 
                                            <label class="vs-input--label ml-1">Código</label>
                                            <vs-input v-model="dadosAtivacao.veiculo.codigoModulo" class="w-full ml-1" readonly="true"/>
                                        </div>
                                    </div>
                                    <div class="vx-row">
                                        <div class="vx-col w-1/2"> 
                                            <label class="vs-input--label ml-1">Serial</label>
                                            <vs-input v-model="dadosAtivacao.veiculo.serialModulo" class="w-full ml-1" readonly="true"/>
                                        </div>
                                        <div class="vx-col w-1/2"> 
                                            <label class="vs-input--label ml-1">Chip</label>
                                            <div class="flex">
                                                <vs-input v-model="dadosAtivacao.veiculo.operadoraGSM" class="w-full ml-1" readonly="true"/>
                                                <vs-input v-model="dadosAtivacao.veiculo.numeroGSM" class="w-full ml-1" readonly="true"/>
                                            </div>
                                        </div>
                                    </div>
                            </vx-card>
                            <vx-card class="ml-1" v-if="dadosAtivacao.veiculo.marcaModuloBkp">
                                    <h5> Módulo Backup</h5>
                                    <div class="vx-row">
                                        <div class="vx-col w-1/2"> 
                                            <label class="vs-input--label ml-1">Marca</label>
                                            <vs-input v-model="dadosAtivacao.veiculo.marcaModuloBkp" class="w-full ml-1" read-only="true"/>
                                        </div>                                
                                        <div class="vx-col w-1/2"> 
                                            <label class="vs-input--label ml-1">Código</label>
                                            <vs-input v-model="dadosAtivacao.veiculo.codigoModuloBkp" class="w-full ml-1" read-only="true"/>
                                        </div>
                                    </div>
                                    <div class="vx-row">
                                        <div class="vx-col w-1/2"> 
                                            <label class="vs-input--label ml-1">Serial</label>
                                            <vs-input v-model="dadosAtivacao.veiculo.serialModuloBkp" class="w-full ml-1" read-only="true"/>
                                        </div>
                                        <div class="vx-col w-1/2"> 
                                            <label class="vs-input--label ml-1">Chip</label>
                                            <div class="flex">
                                                <vs-input v-model="dadosAtivacao.veiculo.operadoraGSMBkp" class="w-full ml-1" read-only="true"/>
                                                <vs-input v-model="dadosAtivacao.veiculo.numeroGSMBkp" class="w-full ml-1" read-only="true"/>
                                            </div>
                                        </div>
                                    </div>
                            </vx-card>
                        </div>
                    </vs-tab>
                    <vs-tab label="Entradas" style="min-height: 250px">
                        <vx-card>                            
                            <div class="vx-row">
                                <div class="vx-col w-1/3">    
                                    <vs-checkbox v-model="dadosAtivacao.veiculo.possuiBotaoPanico" class="w-full"><small>Possui Botão de Pânico</small></vs-checkbox>
                                </div>                                
                                <div class="vx-col w-1/3">    
                                    <vs-checkbox v-model="dadosAtivacao.veiculo.possuiTelemetria" color="success" class="w-full" :disabled="true"><small>Possui Telemetria</small></vs-checkbox>
                                </div>
                                <div class="vx-col w-1/3">    
                                    <vs-checkbox v-model="dadosAtivacao.veiculo.possuiIdentificadorMotorista" color="success" :disabled="true"><small>Possui Identificador de Motorista</small></vs-checkbox>
                                </div>
                            </div>
                            <div class="vx-row mt-2">
                                <div class="vx-col w-1/3">    
                                    <vs-checkbox v-model="dadosAtivacao.veiculo.possuiTeclado" color="success" class="w-full" :disabled="true"><small>Possui Teclado</small></vs-checkbox>
                                </div>
                                <div class="vx-col w-1/3">    
                                    <vs-checkbox v-model="dadosAtivacao.veiculo.possuiSensorOdometro" color="success" class="w-full" :disabled="true"><small>Possui Sensor Odômetro</small></vs-checkbox>
                                </div>
                                <div class="vx-col w-1/3">    
                                    <vs-checkbox v-model="dadosAtivacao.veiculo.possuiSensorRPM" color="success" class="w-full" :disabled="true"><small>Possui Sensor RPM</small></vs-checkbox>
                                </div>
                                
                            </div>
                            <div class="vx-row mt-2">
                                <div class="vx-col w-1/2">    
                                    <label class="vs-input--label ml-1">Sensor RS232</label>
                                    <v-select                                               
                                        :options="CONSTANTE_TIPOS_SENSOR_RS232" 
                                        v-model="dadosAtivacao.veiculo.sensorPortaRS232"                                         
                                        :reduce="r => r.data"     
                                        :disabled="true"                
                                        class="w-full vs-input--label"
                                        >
                                    </v-select>                                
                                </div>
                                <div class="vx-col w-1/2">    
                                    <label class="vs-input--label ml-1">Entrada 1 - {{dadosAtivacao.veiculo.descricaoAvisoAux0}}</label>
                                    <v-select                                               
                                        :options="CONSTANTE_TIPOS_AVISO_AUX" 
                                        v-model="dadosAtivacao.veiculo.avisoAux0"                                         
                                        :reduce="r => r.data"           
                                        :disabled="true"                          
                                        class="w-full vs-input--label"
                                        >
                                    </v-select>  
                                </div>
                            </div>
                            <div class="vx-row mt-2">                                
                                <div class="vx-col w-1/2">    
                                    <label class="vs-input--label ml-1">Entrada 2 - {{dadosAtivacao.veiculo.descricaoAvisoAux1}}</label>
                                    <v-select                                               
                                        :options="CONSTANTE_TIPOS_AVISO_AUX" 
                                        v-model="dadosAtivacao.veiculo.avisoAux1"                                         
                                        :reduce="r => r.data"          
                                        :disabled="true"                           
                                        class="w-full vs-input--label"
                                        >
                                    </v-select>  
                                </div>
                                <div class="vx-col w-1/2">    
                                    <label class="vs-input--label ml-1">Entrada 3 - {{dadosAtivacao.veiculo.descricaoAvisoAux2}}</label>
                                    <v-select                                               
                                        :options="CONSTANTE_TIPOS_AVISO_AUX" 
                                        v-model="dadosAtivacao.veiculo.avisoAux2"                                         
                                        :reduce="r => r.data"         
                                        :disabled="true"                            
                                        class="w-full vs-input--label"
                                        >
                                    </v-select>  
                                </div>
                            </div>
                        </vx-card>
                    </vs-tab>
                    <vs-tab label="Saídas" style="min-height: 250px">
                         <vx-card>                            
                            <div class="vx-row">
                                <div class="vx-col w-1/3">    
                                        <vs-checkbox v-model="dadosAtivacao.veiculo.possuiBloqueio" class="w-full"><small>Possui Bloqueio</small></vs-checkbox>
                                </div>
                                <div class="vx-col w-1/3">    
                                    <vs-checkbox v-model="dadosAtivacao.veiculo.possuiSaida2" color="success" class="w-full" :disabled="true"><small>Possui Saída 2</small></vs-checkbox>
                                </div>
                                <div class="vx-col w-1/3">    
                                    <vs-checkbox v-model="dadosAtivacao.veiculo.possuiSaida3" color="success" class="w-full" :disabled="true"><small>Possui Saída 3</small></vs-checkbox>
                                </div>                              
                            </div>
                         </vx-card>
                    </vs-tab>
                    <vs-tab label="Notificações" style="min-height: 250px">
                        <vs-button size="small" color="rgb(134, 4, 98)" type="filled" class="p-2" style="min-width: 45px" @click="alterarNotificaoTodosVeiculos">Aplicar em todos os veículos</vs-button>
                        <vs-table :data="dadosAtivacao.veiculo.configuracaoNotificacoes" class="tabelaNotificacoesComScroll" stripe>
                            <template slot="thead">
                                <vs-th class="p-0" sort-key="dataTipoAviso">Aviso</vs-th>
                                <vs-th class="p-0">E-mail <vs-button size="small" color="rgb(134, 4, 98)" type="filled" class="ml-2 p-2" style="min-width: 45px" @click.stop="toggleBtnEmail_click">{{toggleBtnEmail}}</vs-button></vs-th>
                                <vs-th class="p-0">SMS <vs-button size="small" color="rgb(134, 4, 98)" type="filled" class="ml-2 p-2" style="min-width: 45px" @click.stop="toggleBtnSMS_click">{{toggleBtnSMS}}</vs-button></vs-th>
                                <vs-th class="p-0">Central <vs-button size="small" color="rgb(134, 4, 98)" type="filled" class="ml-2 p-2" style="min-width: 45px" @click.stop="toggleBtnCentral_click">{{toggleBtnCentral}}</vs-button></vs-th>
                                <vs-th class="p-0">Push <vs-button size="small" color="rgb(134, 4, 98)" type="filled" class="ml-2 p-2" style="min-width: 45px" @click.stop="toggleBtnPush_click">{{toggleBtnPush}}</vs-button></vs-th>
                            </template>
                            <template slot-scope="{data}">
                                <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                                    <vs-td class="p-0" :data="tr.dataTipoAviso" v-if="tr.labelTipoAviso"><small>{{tr.labelTipoAviso}}</small></vs-td>
                                    <vs-td class="p-0" :data="tr.dataTipoAviso" v-if="!tr.labelTipoAviso"><small>{{tr.dataTipoAviso}}</small></vs-td>
                                    <vs-td class="p-0" :data="tr.enviarEmail"><small>{{tr.enviarEmail}}</small>
                                        <template slot="edit">                                                                        
                                            <vs-radio v-model="tr.enviarEmail" vs-name="tr.enviarEmail" vs-value="SIM" class="mr-4"><small>SIM</small></vs-radio>
                                            <vs-radio v-model="tr.enviarEmail" vs-name="tr.enviarEmail" vs-value="NAO"><small>NÃO</small></vs-radio>
                                        </template>
                                    </vs-td>
                                    <vs-td class="p-0" :data="tr.enviarSMS"><small>{{tr.enviarSMS}}</small>
                                        <template slot="edit">                                                                        
                                            <vs-radio v-model="tr.enviarSMS" vs-name="tr.enviarSMS" vs-value="SIM" class="mr-4"><small>SIM</small></vs-radio>
                                            <vs-radio v-model="tr.enviarSMS" vs-name="tr.enviarSMS" vs-value="NAO"><small>NÃO</small></vs-radio>
                                        </template>
                                    </vs-td>
                                    <vs-td class="p-0" :data="tr.enviarCentralMonitoramento"><small>{{tr.enviarCentralMonitoramento}}</small>
                                        <template slot="edit">                                                                        
                                            <vs-radio v-model="tr.enviarCentralMonitoramento" vs-name="tr.enviarCentralMonitoramento" vs-value="SIM" class="mr-4"><small>SIM</small></vs-radio>
                                            <vs-radio v-model="tr.enviarCentralMonitoramento" vs-name="tr.enviarCentralMonitoramento" vs-value="NAO"><small>NÃO</small></vs-radio>
                                        </template>
                                    </vs-td>
                                    <vs-td class="p-0" :data="tr.enviarPush"><small>{{tr.enviarPush}}</small>
                                        <template slot="edit">                                                                        
                                            <vs-radio v-model="tr.enviarPush" vs-name="tr.enviarPush" vs-value="SIM" class="mr-4"><small>SIM</small></vs-radio>
                                            <vs-radio v-model="tr.enviarPush" vs-name="tr.enviarPush" vs-value="NAO"><small>NÃO</small></vs-radio>
                                        </template>
                                    </vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>
                    </vs-tab>
                    <vs-tab label="Acessórios" @click="fetchListaAcessorios" style="min-height: 250px">                         
                         <vs-table :data="listaAcessorios" class="mt-2" noDataText="Nenhum acessório" stripe hoverFlat >
                            <template slot="thead">              
                                <vs-th >Marca</vs-th>
                                <vs-th >Tipo</vs-th>            
                                <vs-th >Modelo</vs-th>            
                                <vs-th >Serial</vs-th>          
                                <vs-th >Valor Mensal</vs-th>                            
                                <vs-th >Local Instalação</vs-th> 
                                <vs-th v-if="habilitarEdicaoMensalidades"></vs-th> 
                            </template>
                            <template slot-scope="{data}">
                                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                    <vs-td><small style='font-family: Verdana, sans-serif;'>{{tr.marca}}</small></vs-td>                
                                    <vs-td><small style='font-family: Verdana, sans-serif;'>{{tr.tipo}}</small></vs-td>  
                                    <vs-td><small style='font-family: Verdana, sans-serif;'>{{tr.modelo}}</small></vs-td>  
                                    <vs-td><small style='font-family: Verdana, sans-serif;'>{{tr.serial}}</small></vs-td>  
                                    <vs-td><small style='font-family: Verdana, sans-serif;'>{{tr.valorIncrementoNaMensalidadeFormatado}}</small></vs-td>                 
                                    <vs-td><small style='font-family: Verdana, sans-serif;'>{{tr.localInstalacaoNoVeiculo}}</small></vs-td>    
                                    <vs-td>
                                        <vx-tooltip text="Clique para alterar o valor da mensalidade">   
                                            <feather-icon v-if="habilitarEdicaoMensalidades" icon="EditIcon" svgClasses="w-4 h-4 stroke-current text-primary"  @click="abrirPopUpValorMenslidadeAcessorio(tr)" />
                                        </vx-tooltip>
                                    </vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>     
                    </vs-tab>
                    <vs-tab label="Anexos" style="min-height: 250px" @click="mudou">
                        <vs-button class="mb-4" color="primary" type="filled" icon="refresh" @click="fetchListaAnexos()" size="small"> Atualizar lista arquivo anexos </vs-button>
                        <div class="vx-row flex">
                            <div class="vx-col w-3/4">
                                <div class="flex-wrap">
                                    <div v-for="arquivo in listaAnexos" :key="arquivo.id" class="imgHolder">
                                        <img :src="getFullpathAnexo(arquivo)"  style="max-width: 210px;" @click="verDocumento(arquivo)" v-if="isFileImage(arquivo.nomeDocumento)"/>
                                        <embed
                                           v-if="isFilePDF(arquivo.nomeDocumento)"
                                            :src="getFullpathAnexo(arquivo)"
                                            type="application/pdf"
                                            frameBorder="0"
                                            scrolling="auto"                                            
                                            height="100%"
                                            width="100%"
                                        />
                                        <video width="100%" height="100%" controls v-if="isFileVideo(arquivo.nomeDocumento)">
                                            <source :src="getFullpathAnexo(arquivo)">                                        
                                            Your browser does not support the video tag.
                                        </video>
                                        <span @click="verDocumento(arquivo)" class="w-full mb-2 font-bold">{{arquivo.legenda}}</span>
                                        <div class="-full flex">
                                            <vs-button color="danger" size="small" type="filled" icon="delete" @click="excluirAnexo(arquivo)" class="ml-auto "></vs-button>
                                            <vs-button color="primary" size="small" type="filled" icon-pack="feather" icon="icon-download" @click="verDocumento(arquivo)" class="ml-2 mr-auto"></vs-button>
                                        </div>
                                    </div>
                                </div>
                            </div>                        
                            <div class="vx-col w-1/4 flex-items-center">
                                   <vs-input class="w-full" v-model="dadosUpload.legendaArquivo" :maxlength="255" placeholder="Informe a legenda para o arquivo"/> 

                                   <file-upload
                                        class="btn btn-primary w-full  mt-4"                            
                                        :post-action="getUrlPost"                                                                                   
                                        :multiple="false"
                                        :data="dadosUpload"                                                            
                                        v-model="arquivosParaUpload"                             
                                        ref="upload">
                                        <i class="fa fa-plus"></i> Selecione o arquivo
                                    </file-upload>
                                    <div class="vx-col w-full flex items-center" v-if="arquivosParaUpload && arquivosParaUpload.length > 0" >                                                    
                                        <vs-input class="w-full" v-model="arquivosParaUpload[0].name" />                                    
                                        <vs-button class="ml-4" color="rgb(11, 189, 135)" type="filled" icon-pack="feather" icon="icon-upload" v-if="!$refs.upload || !$refs.upload.active" :disabled="!dadosUpload.legendaArquivo || dadosUpload.legendaArquivo.length < 5 || arquivosParaUpload.length === 0 || $refs.upload.uploaded " @click.prevent="enviarArquivoUpload()"></vs-button>
                                    </div>                                                                        
                                    <vs-row class="mt-4" vs-type="flex" vs-justify="center" v-if="arquivosParaUpload && arquivosParaUpload[0] && arquivosParaUpload[0].success">
                                        <vs-col vs-type="flex" vs-align="center" vs-w="12" vs-justify="center">
                                            <div class="justify-center w-full">                                     
                                                <div class="op-block w-full text-center">{{exibirMensagelResultadoUpload(arquivosParaUpload[0].response)}}</div>
                                            </div>
                                        </vs-col>                    
                                    </vs-row>
                            </div>
                        </div>

                    </vs-tab>
                 </vs-tabs>            
            </tab-content>
        </form-wizard>
    </div>
        
  </div>
</template>

<script>
import axios from "@/axios.js"
import FileUpload from 'vue-upload-component'
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { CONSTANTE_MARCAS_MODULO } from '@/constantesComboBox.js'
import { CONSTANTE_TIPO_VEICULO } from '@/constantesImagens.js'
import { CONSTANTE_TIPO_CONTRATO_VEICULO } from '@/constantesComboBox.js'
import { CONSTANTE_TIPOS_SENSOR_RS232 } from '@/constantesComboBox.js'
import { CONSTANTE_TIPOS_AVISO_AUX } from '@/constantesComboBox.js'
import { CONSTANTE_ORIGEM_DADOS } from '@/constantesComboBox.js'


export default {   
    components: {
        FormWizard,
        TabContent,
        VueAutonumeric,
        FileUpload
    }, 
    created() {
        this.CONSTANTE_MARCAS_MODULO = CONSTANTE_MARCAS_MODULO;
        this.listaTiposVeiculos = CONSTANTE_TIPO_VEICULO;  
        this.CONSTANTE_TIPO_CONTRATO_VEICULO = CONSTANTE_TIPO_CONTRATO_VEICULO;
        this.CONSTANTE_TIPOS_SENSOR_RS232 = CONSTANTE_TIPOS_SENSOR_RS232;
        this.CONSTANTE_TIPOS_AVISO_AUX = CONSTANTE_TIPOS_AVISO_AUX;
        this.CONSTANTE_ORIGEM_DADOS = CONSTANTE_ORIGEM_DADOS;
    },
    computed: {
        getTituloTab1() {
            if (this.veiculoSelecionado && this.veiculoSelecionado.idVeiculo) {
                return "1. Veículo selecionado: " + this.veiculoSelecionado.placa;
            } else if (this.veiculoSelecionado && !this.veiculoSelecionado.idVeiculo) {
                return "1. Novo Veículo";
            } else {
                return "1.Selecione o veículo.";
            }
        } ,
        habilitarEdicaoMensalidades() {
            let perfilLogado = this.$store.state.AppActiveUser.perfil;
            if (perfilLogado === "ATIVADOR" || perfilLogado === "CONFIGURADOR" || perfilLogado === "SUPERVISOR" || perfilLogado === "FINANCEIRO" 
            || perfilLogado === "MONITOR_PANICO" || perfilLogado === "EMPRESA_MONITOR_PANICO" || perfilLogado === "EMPRESA_SUPERVISOR" || perfilLogado === "EMPRESA_ATIVADOR") {
                return false;
            }

            return true;
        },
        desabilitarMonitoravelCentral() {
            if (this.dadosAtivacao && this.dadosAtivacao.clienteSelecionado && this.dadosAtivacao.clienteSelecionado.monitoravelPorCentral) {
                return false;
            }

            return true;
        },
        getTextoMonitoravelCentral(){
            if (this.dadosAtivacao && this.dadosAtivacao.clienteSelecionado && this.dadosAtivacao.clienteSelecionado.monitoravelPorCentral) {
                return "Exibir os avisos desse veículo em Backend -> Monitoramento ";
            }

            return "O cliente não está com o Monitoramento habilitado em Cadastros --> Clientes";
        },
        qtdRegistros() {
            return this.$refs.table ? this.$refs.table.queriedResults.length : this.listaVeiculos.length
        },
        getUrlPost() {
          return axios.defaults.baseURL + "/UploadDocumentos?processUploadedFile"; 
        },
        filteredVeiculos: function () {
            var self = this;
            var arrayRetorno = self.listaVeiculos;  

            arrayRetorno = arrayRetorno.filter(function (veiculo) {
                 let termoUC = ( self.termoPesquisa != null ? self.termoPesquisa.toUpperCase() : null);
                 return (termoUC ? (veiculo.modelo.toUpperCase().indexOf(termoUC) != -1 ||                            
                                    veiculo.condutor.toUpperCase().indexOf(termoUC) != -1 || 
                                    (veiculo.labelVeiculo && veiculo.labelVeiculo.toUpperCase().indexOf(termoUC) != -1)|| 
                                    veiculo.placa.toUpperCase().indexOf(termoUC) != -1): true)
            });

            if (self.divisaoSelecionada != null) {
                arrayRetorno = arrayRetorno.filter(function (veiculo) {
                    return veiculo.hasOwnProperty('idDivisao') && self.divisaoSelecionada.id === veiculo.idDivisao;
                })
                
            }

            return arrayRetorno;
        },
    },
    data() {
        return {  
            txtTermoPesquisa: null,
            termoPesquisa: null,
            listaDivisoesVeiculo: [],
            divisaoSelecionada: null,
            arquivosParaUpload: [],
            dadosUpload: { 'clientId': null, 'empresaId': null, 'idVeiculo': null, legendaArquivo: null, 'destino' : 'veiculo' , 'outputFormat': 'JSON'},        
            isArquivoProcessado: false,    
            listaAnexos : [],      
            dadosAtivacao: {                
                veiculo: { tipoVeiculo: null, marca: null, modelo:null, modeloOutro: null, placa: null, condutor: null, 
                    autonomia: null, autonomiaPorHora: null, mensalidade: null, tipoContratoVeiculo: null, prazoContratoVeiculoEmMeses: null, possuiBotaoPanico: false,
                    possuiBloqueio: false, centralMonitoramento: false, procedimentoPanico: null, 
                    nome1ContatoPanico: null, telefone1ContatoPanico: null,
                    nome2ContatoPanico: null, telefone2ContatoPanico: null,
                    nome3ContatoPanico: null, telefone3ContatoPanico: null,
                    emailAvisoExcessoVelocidade: null, emailAvisoSaidaCerca: null, senhaVeiculo: null, manterMotoristaVeiculoDesligado: false, 
                    nomeContatoResponsavel1: null,  nomeContatoResponsavel2: null, anoModelo: null, anoFabricacao: null, corVeiculo: null, correcaoOdometro: null, correcaoHorimetro: null, labelVeiculo: null  },
                clienteSelecionado: null,                
                dadosAdicionaisClienteSelecionado: null,
            },
            listaClientes: [],
            listaVeiculos: [],              
            listaMarcaVeiculos: [], 
            listaModeloVeiculos: [], 
            exibirMensagemNenhumDadoEncontrado: false,   
            veiculoSelecionado: null,       
            configuracaoPopUpMotoristas: {
                condutorOriginal: null, 
                exibirPopUpMotorista: false,
                motoristasDoPopUp: null,              
                idClienteSelecionado: null,
                mensagemExplicacao: ""
            },    
            idNovoMotorista: null,      
            toggleBtnEmail: 'SIM',
            toggleBtnSMS: 'SIM',
            toggleBtnCentral: 'SIM',
            toggleBtnPush: 'SIM',  
            listaAcessorios: [],
            popUpAcessorio: {
                exibir: false, 
                valorMensalidadeAcessorio: 0,
            },
            tabSelecionado: null,
            errorsValidacao: [],  
            publicPath: process.env.BASE_URL,
            arquivoSelecionado: null
        }
    },
    watch: {
        tabSelecionado(val) {
            this.mudou(val);
        }
    },
    methods: {
        pesquisarPorTermo(valorTermo){
            this.termoPesquisa = valorTermo;                      
        },
        fetchListaDivisaoVeiculos() {
            const params = new URLSearchParams();
            params.append('idCliente', this.dadosAtivacao.clienteSelecionado.id);

            axios.post("/rest/DivisaoVeiculo/Listar", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {                    
                if (response.data.Resultado.quantidade > 1) {
                  this.listaDivisoesVeiculo = response.data.Resultado.lista.item;
                } else if (response.data.Resultado.quantidade === 1) {
                    this.listaDivisoesVeiculo = [];
                    this.listaDivisoesVeiculo.push(response.data.Resultado.lista.item);
                } else {
                    this.listaDivisoesVeiculo = [];
                }    
            })
            .catch((error) => { 
                /* eslint-disable no-console */  console.log("Error Fetch Lista Marcas Veiculos " + error);        
            })      
        },   
        mudou(tab) {
            if (tab === 9) {
                this.$refs.fwAtivacao.hideButtons  = true;
            } else {
                this.$refs.fwAtivacao.hideButtons  = false;
            }
        },
        excluirAnexo(arquivo) {
            this.arquivoSelecionado = arquivo;
            this.$vs.dialog({
                type: "confirm", color: "warning", title: "Atenção", text: "Deseja excluir ?", acceptText: "Sim", cancelText: "Não", accept: this.excluirAnexoRequest});
        },
        excluirAnexoRequest() {
            this.$vs.loading();
            const params = new URLSearchParams();
            params.append("idVeiculo", this.dadosAtivacao.veiculo.idVeiculo);
            params.append("id", this.arquivoSelecionado.id);
            params.append("nomeDocumento", this.arquivoSelecionado.nomeAnexo);

            axios.post("/rest/DocumentosAnexos/Remover", params, {
                credentials: "include", responseEncoding: "iso88591", withCredentials: true})
                .then((response) => {
                    this.$vs.loading.close();
                    if ( response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                        this.$router.push({ path: "/login" });
                    } else {
                        if (response.data.Resultado.hasOwnProperty("error")) {
                        this.$vs.dialog({ color: "danger", title: "Erro", text: response.data.Resultado.error, acceptText: "OK", });
                        } else {
                            this.fetchListaAnexos();
                        }
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error
                );
            });
        },
        isFileVideo(nomeArquivo) {
            return (nomeArquivo.indexOf(".mp4") != -1 || nomeArquivo.indexOf(".ogg") != -1 || nomeArquivo.indexOf(".avi") != -1)
        },
        isFilePDF(nomeArquivo) {
            return (nomeArquivo.indexOf(".pdf") != -1)
        },
        isFileImage(nomeArquivo) {
            return (nomeArquivo.indexOf(".jpeg") != -1 || nomeArquivo.indexOf(".jpg") != -1 || nomeArquivo.indexOf(".png") != -1)
        },
        getFullpathAnexo(arquivo) {
            let ultimoIndice = arquivo.nomeDocumento.lastIndexOf(".") ;            
            return "https://documentos-veiculo-setup.s3.amazonaws.com/" + this.$store.state.AppActiveUser.idEmpresa + "/" + this.dadosAtivacao.veiculo.idVeiculo + "/" + arquivo.id + "." + arquivo.nomeDocumento.substring(ultimoIndice+1);    
                
        },
        verDocumento(arquivo) { 
            window.open(this.getFullpathAnexo(arquivo), "_blank");    
        },
        fetchListaAnexos() {
            const params = new URLSearchParams();                    
            params.append('id', this.dadosAtivacao.veiculo.idVeiculo);

            axios.post("/rest/DocumentosAnexos/Listar", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {          
                this.dadosDaGridAnexos = [];                   
                if (response.data.Resultado.quantidade > 1) {
                    this.listaAnexos = response.data.Resultado.lista.item;
                } else if (response.data.Resultado.quantidade === 1) {
                    this.listaAnexos = [];
                    this.listaAnexos.push(response.data.Resultado.lista.item);
                } else {
                    this.listaAnexos = [];
                }    
            })
            .catch((error) => { 
                /* eslint-disable no-console */ console.log("Erro fetchListaDocumentos " + error);        
            })      
        }, 
        enviarArquivoUpload(){            
            this.$vs.loading();                  
            this.dadosUpload.clientId = this.dadosAtivacao.clienteSelecionado.id;
            this.dadosUpload.veiculoId = this.dadosAtivacao.veiculo.idVeiculo; 
            this.dadosUpload.empresaId = this.$store.state.AppActiveUser.idEmpresa; 
            
            this.$refs.upload.update(this.arquivosParaUpload[0], { data: this.dadosUpload });
            this.$refs.upload.active = true
        },
        removeArquivoUpload() {
            this.isArquivoProcessado = false;
            this.$refs.upload.remove(this.arquivosParaUpload[0]);            
        },
        exibirMensagelResultadoUpload(resposta) {            
            this.$vs.loading.close();              
            if (resposta) {            
                if (resposta.hasOwnProperty("mensagem")) {                                                                     
                    if (resposta.indexOf('sucesso') != -1 ) {
                        this.fetchListaAnexos();
                    }                             
                    return resposta.mensagem;
                }
            }
            return "";
        }, 
        getModelo(tr) {
            if (tr.modelo === 'Outro') {
                return tr.modeloOutro;
            } else {
                return tr.modelo;
            }
        },
        abrirPopUpValorMenslidadeAcessorio(tr) {
            this.popUpAcessorio.exibir = true;
            this.popUpAcessorio.acessorio = tr;                        
        },   
        selecionarCliente(cliente) {
            if (cliente) {
                this.fetchListaVeiculos();
                this.fetchListaDivisaoVeiculos();
            } else {
                this.dadosAtivacao.veiculo= { tipoVeiculo: null, marca: null, modelo:null, modeloOutro: null, placa: null, condutor: null, 
                    autonomia: null, autonomiaPorHora: null, mensalidade: null, tipoContratoVeiculo: null, prazoContratoVeiculoEmMeses: null, possuiBotaoPanico: false,
                    possuiBloqueio: false, centralMonitoramento: false, procedimentoPanico: null, 
                    nome1ContatoPanico: null, telefone1ContatoPanico: null,
                    nome2ContatoPanico: null, telefone2ContatoPanico: null,
                    nome3ContatoPanico: null, telefone3ContatoPanico: null,
                    emailAvisoExcessoVelocidade: null, emailAvisoSaidaCerca: null, manterMotoristaVeiculoDesligado: false,
                    nomeContatoResponsavel1: null,  nomeContatoResponsavel2: null, anoModelo: null, anoFabricacao: null, corVeiculo: null, correcaoOdometro: null, correcaoHorimetro: null, labelVeiculo: null };

                this.dadosAtivacao.clienteSelecionado = null;
                this.dadosAtivacao.dadosAdicionaisClienteSelecionado = null;
                this.veiculoSelecionado = null;
                this.tabSelecionado = 0;
                this.listaVeiculos = [];
            }
        }, 
        selecionarVeiculo(tr) {
            this.veiculoSelecionado = JSON.parse(JSON.stringify(tr));
            this.dadosAtivacao.veiculo = JSON.parse(JSON.stringify(tr));
            this.tabSelecionado = 0;
            this.$refs.fwAtivacao.nextTab();
            this.fetchListaAnexos();
        },
        exibirMenssageErro: function(errorMsg){
            if (errorMsg) {
                this.$vs.notify({
                    title: "Erro", text: errorMsg, time: 10000,
                    iconPack: 'feather', icon: 'icon-alert-circle', color: 'danger' });
            }
        },
        validarPasso1() {
            let self_ = this;

            return new Promise((resolve, reject) => {
                if (self_.dadosAtivacao.veiculo && self_.dadosAtivacao.veiculo.idVeiculo) { 
                    self_.fetchDadosAdicionaisVeiculo();                   
                    return resolve(true);
                }
                return reject("Selecione um veículo");
            });
        },  
        excluirSenhaIndividual(){
            let self_ = this;
            this.$vs.dialog({
                type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja excluir senha indivudual do veículo '
                 + self_.dadosAtivacao.veiculo.placa + ' ? ', acceptText: 'Sim', cancelText: 'Não', accept: this.excluirSenhaIndividualRequest
            });
        },    
        excluirSenhaIndividualRequest(){
            this.$vs.loading();  
            const params = new URLSearchParams();  
            params.append('outputFormat', 'JSON');
            params.append('idCliente', this.dadosAtivacao.clienteSelecionado.id);   
            params.append('idVeiculo', this.dadosAtivacao.veiculo.idVeiculo);   
            params.append('acao', 'EXCLUIR_SENHA_INDIVIDUAL');
            params.append('placaVeiculo', this.dadosAtivacao.veiculo.placa);  
            axios.post("/AlterarVeiculo", params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.$vs.loading.close();
                    this.$vs.notify({
                            title: "Mensagem", text: response.data.mensagem, time: 10000,
                            iconPack: 'feather', icon: 'icon-alert-circle', color: 'success' });
                    this.dadosAtivacao.veiculo.possuiSenhaIndividual = false;
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();                
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error);                 
            })  
        },
        ativar() {
            if (!this.formularioValido()) {
                return;
            }
            let self_ = this;
            this.$vs.dialog({
                type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja alterar os dados do veículo '
                 + self_.dadosAtivacao.veiculo.placa + ' ? ', acceptText: 'Sim', cancelText: 'Não', accept: this.ativarRequest
            });
        },
        ativarRequest() {
            this.$vs.loading();  
            const params = new URLSearchParams();  
            params.append('outputFormat', 'JSON');
            params.append('idCliente', this.dadosAtivacao.clienteSelecionado.id);   
            params.append('idVeiculo', this.dadosAtivacao.veiculo.idVeiculo);   
            params.append('cnpjCPF', this.dadosAtivacao.clienteSelecionado.CNPJCPF);
            params.append('tipoVeiculo', this.dadosAtivacao.veiculo.tipoVeiculo);   
            params.append('marcaVeiculo', this.dadosAtivacao.veiculo.marca);   
            params.append('modeloVeiculo', this.dadosAtivacao.veiculo.modelo);   
            if (this.dadosAtivacao.veiculo.modeloOutro) {
                params.append('modeloVeiculoOutro', this.dadosAtivacao.veiculo.modeloOutro);   
            }
            if (this.dadosAtivacao.veiculo.labelVeiculo) {
                params.append('labelVeiculo', this.dadosAtivacao.veiculo.labelVeiculo); 
            }
            params.append('placaVeiculo', this.dadosAtivacao.veiculo.placa);   
            params.append('autonomia', this.dadosAtivacao.veiculo.autonomia);   
            params.append('autonomiaPorHora', this.dadosAtivacao.veiculo.autonomiaPorHora);   
                        
            params.append('possuiBotaoPanico', this.dadosAtivacao.veiculo.possuiBotaoPanico);
            
            if (this.dadosAtivacao.veiculo.procedimentoPanico) { params.append('procedimentoPanico', this.dadosAtivacao.veiculo.procedimentoPanico);  }                        
            if (this.dadosAtivacao.veiculo.nome1ContatoPanico) { params.append('nome1ContatoPanico', this.dadosAtivacao.veiculo.nome1ContatoPanico); }
            if (this.dadosAtivacao.veiculo.nome2ContatoPanico) { params.append('nome2ContatoPanico', this.dadosAtivacao.veiculo.nome2ContatoPanico); }
            if (this.dadosAtivacao.veiculo.nome3ContatoPanico) { params.append('nome3ContatoPanico', this.dadosAtivacao.veiculo.nome3ContatoPanico); }
            if (this.dadosAtivacao.veiculo.telefone1ContatoPanico) { params.append('telefone1ContatoPanico', this.dadosAtivacao.veiculo.telefone1ContatoPanico); }
            if (this.dadosAtivacao.veiculo.telefone2ContatoPanico) { params.append('telefone2ContatoPanico', this.dadosAtivacao.veiculo.telefone2ContatoPanico); }
            if (this.dadosAtivacao.veiculo.telefone3ContatoPanico) { params.append('telefone3ContatoPanico', this.dadosAtivacao.veiculo.telefone3ContatoPanico); }
            if (this.dadosAtivacao.veiculo.telefone1ContatoPanico) { params.append('senha1ContatoPanico', this.dadosAtivacao.veiculo.senha1ContatoPanico); }
            if (this.dadosAtivacao.veiculo.telefone2ContatoPanico) { params.append('senha2ContatoPanico', this.dadosAtivacao.veiculo.senha2ContatoPanico); }
            if (this.dadosAtivacao.veiculo.telefone3ContatoPanico) { params.append('senha3ContatoPanico', this.dadosAtivacao.veiculo.senha3ContatoPanico); }

            params.append('condutorNome', this.dadosAtivacao.veiculo.condutor);  
            
            if (this.idNovoMotorista) {
                params.append('isNovoCondutor', true);
                params.append('idMotorista', this.idNovoMotorista);
            } else {
                params.append('isNovoCondutor', false);
            }
            params.append('possuiBloqueio', this.dadosAtivacao.veiculo.possuiBloqueio);            
            params.append('possuiCentralMonitoramento', this.dadosAtivacao.veiculo.possuiCentralMonitoramento);

            params.append('mensalidade', this.dadosAtivacao.veiculo.mensalidade);   
            if (this.dadosAtivacao.veiculo.mensalidadeBkp) {
                params.append('mensalidadeBkp', this.dadosAtivacao.veiculo.mensalidadeBkp);   
            }

            if (this.dadosAtivacao.veiculo.emailAvisoExcessoVelocidade) {
                params.append('emailAvisoExcessoVelocidade', this.dadosAtivacao.veiculo.emailAvisoExcessoVelocidade);   
            }
            if (this.dadosAtivacao.veiculo.celularlAvisoExcessoVelocidade) {
                params.append('celularAvisoExcessoVelocidade', this.dadosAtivacao.veiculo.celularlAvisoExcessoVelocidade);   
            }
            if (this.dadosAtivacao.veiculo.emailAvisoSaidaCerca) {
                params.append('emailAvisoSaidaCerca', this.dadosAtivacao.veiculo.emailAvisoSaidaCerca);   
            }
            if (this.dadosAtivacao.veiculo.celularAvisoSaidaCerca) {
                params.append('celularAvisoSaidaCerca', this.dadosAtivacao.veiculo.celularAvisoSaidaCerca);   
            }
            
            params.append('tipoContratoVeiculo', this.dadosAtivacao.veiculo.tipoContratoVeiculo);
            params.append('prazoContratoVeiculoEmMeses', this.dadosAtivacao.veiculo.prazoContratoVeiculoEmMeses);
            if (this.dadosAtivacao.veiculo.tempoSemConexaoParaAlerta){
                params.append('tempoSemConexaoParaAlerta', this.dadosAtivacao.veiculo.tempoSemConexaoParaAlerta);
            }
            if (this.dadosAtivacao.veiculo.tempoSemConexaoIgnicaoONParaAlerta){
                params.append('tempoSemConexaoIgnicaoONParaAlerta', this.dadosAtivacao.veiculo.tempoSemConexaoIgnicaoONParaAlerta);
            }
            params.append('ativarEspelhamento', this.dadosAtivacao.veiculo.ativarEspelhamento);
            
            if (this.dadosAtivacao.veiculo.taxaAtualizacao) {
                params.append('taxaAtualizacaoPorMinuto', this.dadosAtivacao.veiculo.taxaAtualizacao);
            }
            if (this.dadosAtivacao.veiculo.taxaAtualizacaoRPM) {
                params.append('taxaAtualizacaoPorRPMMinuto', this.dadosAtivacao.veiculo.taxaAtualizacaoRPM);
            }
            if (this.dadosAtivacao.veiculo.origemDadosOdometro) {
                params.append('origemDadosOdometro', this.dadosAtivacao.veiculo.origemDadosOdometro);
            }
            if (this.dadosAtivacao.veiculo.origemDadosHorimetro) {
                params.append('origemDadosHorimetro', this.dadosAtivacao.veiculo.origemDadosHorimetro);
            }
            if (this.dadosAtivacao.veiculo.corVeiculo) {
                params.append('corVeiculo', this.dadosAtivacao.veiculo.corVeiculo);
            }

            if (this.dadosAtivacao.veiculo.correcaoOdometro) {
                params.append('correcaoOdometro', this.dadosAtivacao.veiculo.correcaoOdometro);
            }

            if (this.dadosAtivacao.veiculo.correcaoHorimetro) {
                params.append('correcaoHorimetro', this.dadosAtivacao.veiculo.correcaoHorimetro);
            }

            params.append('possuiMonitriip', this.dadosAtivacao.veiculo.possuiMonitriip)
            
            params.append('JSONItems',  JSON.stringify(this.dadosAtivacao.veiculo.configuracaoNotificacoes));

            if (this.dadosAtivacao.veiculo.senhaVeiculo) {
                params.append('senhaVeiculo', this.dadosAtivacao.veiculo.senhaVeiculo);
            }

            params.append('manterMotoristaVeiculoDesligado', this.dadosAtivacao.veiculo.manterMotoristaVeiculoDesligado);
            if (this.dadosAtivacao.veiculo.nomeContatoResponsavel1) {
                params.append('nomeContatoResponsavel1', this.dadosAtivacao.veiculo.nomeContatoResponsavel1);
            }
            if (this.dadosAtivacao.veiculo.nomeContatoResponsavel2) {
                params.append('nomeContatoResponsavel2', this.dadosAtivacao.veiculo.nomeContatoResponsavel2);
            }

            if (this.dadosAtivacao.veiculo.anoFabricacao) {
                params.append('anoFabricacao', this.dadosAtivacao.veiculo.anoFabricacao);
            }

            if (this.dadosAtivacao.veiculo.anoModelo) {
                params.append('anoModelo', this.dadosAtivacao.veiculo.anoModelo);
            }

            if (this.dadosAtivacao.veiculo.labelVeiculo) {
                params.append('labelVeiculo', this.dadosAtivacao.veiculo.labelVeiculo);
            }

            axios.post("/AlterarVeiculo", params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.$vs.loading.close();
                    if (response.data.mensagem.toLowerCase().indexOf('erro') !== -1) {
                        this.$vs.notify({
                                title: "Mensagem", text: response.data.mensagem, time: 10000,
                                iconPack: 'feather', icon: 'icon-alert-circle', color: 'danger' });
                    } else {
                        this.$vs.notify({
                                title: "Mensagem", text: response.data.mensagem, time: 10000,
                                iconPack: 'feather', icon: 'icon-alert-circle', color: 'success' });
                        this.$refs.fwAtivacao.reset();
                        this.dadosAtivacao.veiculo = { tipoVeiculo: null, marca: null, modelo:null, modeloOutro: null, placa: null, condutor: null, 
                                        autonomia: null, autonomiaPorHora: null, mensalidade: null, tipoContratoVeiculo: null, prazoContratoVeiculoEmMeses: null, possuiBotaoPanico: false,
                                        possuiBloqueio: false, centralMonitoramento: false, procedimentoPanico: null, nome1ContatoPanico: null, telefone1ContatoPanico: null,
                                        nome2ContatoPanico: null, telefone2ContatoPanico: null, nome3ContatoPanico: null, telefone3ContatoPanico: null, emailAvisoExcessoVelocidade: null, emailAvisoSaidaCerca: null, 
                                        manterMotoristaVeiculoDesligado: false, nomeContatoResponsavel1: null,  nomeContatoResponsavel2: null, anoModelo: null, anoFabricacao: null, corVeiculo: null, 
                                        correcaoOdometro: null, correcaoHorimetro: null, labelVeiculo: null };
                        this.veiculoSelecionado = null;
                        this.fetchListaVeiculos();
                    }
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();                
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error);                 
            })  
        },
        pesquisarCliente(options, varTermoPesquisa) {
            varTermoPesquisa = varTermoPesquisa.toUpperCase();
            return options.filter(function (c) {
                    return (c.nome.toUpperCase().indexOf(varTermoPesquisa) != -1  || c.razaoSocial.toUpperCase().indexOf(varTermoPesquisa) != -1 || 
                    c.CNPJCPF.toUpperCase().indexOf(varTermoPesquisa) != -1);
            })
        },        
        fetchListaVeiculos() {     
            if (!this.dadosAtivacao.clienteSelecionado) {
                return;
            } else {
                if (this.veiculoSelecionado && this.veiculoSelecionado.id) {
                    this.$refs.fwAtivacao.reset();
                    this.dadosAtivacao.veiculo = { tipoVeiculo: null, marca: null, modelo:null, modeloOutro: null, placa: null, condutor: null, 
                                            autonomia: null, autonomiaPorHora: null, mensalidade: null, tipoContratoVeiculo: null, prazoContratoVeiculoEmMeses: null, possuiBotaoPanico: false,
                                            possuiBloqueio: false, centralMonitoramento: false, procedimentoPanico: null, nome1ContatoPanico: null, telefone1ContatoPanico: null,
                                            nome2ContatoPanico: null, telefone2ContatoPanico: null, nome3ContatoPanico: null, telefone3ContatoPanico: null, emailAvisoExcessoVelocidade: null, 
                                            emailAvisoSaidaCerca: null, taxaAtualizacaoRPM: null, manterMotoristaVeiculoDesligado: false,
                                            nomeContatoResponsavel1: null,  nomeContatoResponsavel2: null, anoModelo: null, anoFabricacao: null, corVeiculo: null, correcaoOdometro: null, correcaoHorimetro: null, labelVeiculo: null };
                    this.veiculoSelecionado = null;
                    this.listaVeiculos = [];
                }
            }
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');            
            params.append('cpfcnpj', this.dadosAtivacao.clienteSelecionado.CNPJCPF);
            
            axios.post("/RecuperarClientePorCNPJouCPFComVeiculosAtivos", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaVeiculos = response.data.listaVeiculos;  
                    this.dadosAtivacao.dadosAdicionaisClienteSelecionado = response.data.dadosCliente;
                    this.exibirMensagemNenhumDadoEncontrado = this.listaVeiculos.length === 0;

                    if (this.listaVeiculos.length > 0 && this.dadosAtivacao.veiculo && this.dadosAtivacao.veiculo.placa) {
                        this.veiculoSelecionado = this.dadosAtivacao.veiculo;
                    } else {
                        this.veiculoSelecionado = null;
                    }
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaVeiculos - " + error); 
            })    
                                
        },
        fetchDadosAdicionaisVeiculo() {     
            if (!this.dadosAtivacao.clienteSelecionado) {
                return;
            } 

            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');            
            params.append('acao', 'RECUPERAR_DADOS_COMPLEMENTARES_VEICULO');            
            params.append('cpfcnpj', this.dadosAtivacao.clienteSelecionado.CNPJCPF);
            params.append('idVeiculo', this.dadosAtivacao.veiculo.idVeiculo);
            
            axios.post("/RecuperarClientePorCNPJouCPFComVeiculosAtivos", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {                    
                    this.$set(this.dadosAtivacao.veiculo, 'configuracaoNotificacoes', response.data.configuracaoNotificacoes);           
                    if (response.data.hasOwnProperty('nome1ContatoPanico')) {
                        this.$set(this.dadosAtivacao.veiculo, 'nome1ContatoPanico', response.data.nome1ContatoPanico);           
                        this.$set(this.dadosAtivacao.veiculo, 'telefone1ContatoPanico', response.data.telefone1ContatoPanico);           
                        this.$set(this.dadosAtivacao.veiculo, 'senha1ContatoPanico', response.data.senha1ContatoPanico);           
                    } 

                    if (response.data.hasOwnProperty('nome2ContatoPanico')) {
                        this.$set(this.dadosAtivacao.veiculo, 'nome2ContatoPanico', response.data.nome2ContatoPanico);           
                        this.$set(this.dadosAtivacao.veiculo, 'telefone2ContatoPanico', response.data.telefone2ContatoPanico);           
                        this.$set(this.dadosAtivacao.veiculo, 'senha2ContatoPanico', response.data.senha2ContatoPanico);           
                    } 

                    if (response.data.hasOwnProperty('nome3ContatoPanico')) {
                        this.$set(this.dadosAtivacao.veiculo, 'nome3ContatoPanico', response.data.nome3ContatoPanico);           
                        this.$set(this.dadosAtivacao.veiculo, 'telefone3ContatoPanico', response.data.telefone3ContatoPanico);           
                        this.$set(this.dadosAtivacao.veiculo, 'senha3ContatoPanico', response.data.senha3ContatoPanico);           
                    } 

                    if (response.data.hasOwnProperty('taxaAtualizacaoRPM')) {                        
                        this.$set(this.dadosAtivacao.veiculo, 'taxaAtualizacaoRPM', response.data.taxaAtualizacaoRPM);           
                    }

                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchDadosAdicionaisVeiculo - " + error); 
            })    
                                
        },
        fetchListaClientes() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('method', 'FindAll');
            params.append('orderDirection', 'ASC');
            params.append('outputFormat', 'JSON');
            params.append('naoExibirInativos', 'true');
            
            if (this.$store.state.AppActiveUser.perfil === "MONITOR_PANICO" || this.$store.state.AppActiveUser.perfil === "EMPRESA_MONITOR_PANICO") {
                params.append('monitoravelPorCentral', 'true');
            }
            
            axios.post("/ClientesRest", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaClientes = response.data;                            
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
                                
        }, 
        fetchListaMarcaVeiculos() {
            axios.get("/rest/MarcaVeiculo/Listar", null, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {                    
                this.listaMarcaVeiculos = response.data.Resultado.lista.item;
            })
            .catch((error) => { 
                /* eslint-disable no-console */  console.log("Error Fetch Lista Marcas Veiculos " + error);        
            })      
        },  
        fetchListaModeloVeiculos() {
            const params = new URLSearchParams();
            params.append('pagina', -1);
            axios.post("/rest/ModeloVeiculo/Listar", params, { 
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {                    
                this.listaModeloVeiculos = response.data.Resultado.lista.item;
            })
            .catch((error) => { 
                /* eslint-disable no-console */  console.log("Error Fech Lista Modelo Veiculos " + error);        
            })      
        }, 
        abrirPopUpMotoristas() {
            this.configuracaoPopUpMotoristas.idClienteSelecionado = this.dadosAtivacao.clienteSelecionado.id;
            this.configuracaoPopUpMotoristas.motoristasDoPopUp = null;
            this.configuracaoPopUpMotoristas.exibirPopUpMotorista = true;
        },
        onSelecionarMotorista(motoristasSelecionados) {            
            this.configuracaoPopUpMotoristas.motoristasDoPopUp = motoristasSelecionados;                  
        },
        alterarMotorista() {
            this.dadosAtivacao.veiculo.condutor = this.configuracaoPopUpMotoristas.motoristasDoPopUp[0].nome;
            this.idNovoMotorista = this.configuracaoPopUpMotoristas.motoristasDoPopUp[0].id;
        },
        fecharPopUpMotoristas() {
            this.configuracaoPopUpMotoristas.motoristasDoPopUp = null;
            this.idNovoMotorista = null;
        },     
        toggleBtnEmail_click() {
            let novoValor = 'SIM';
            if (this.toggleBtnEmail === 'SIM') {
                this.toggleBtnEmail = 'NÃO';            
                novoValor = 'SIM';
            } else {
                this.toggleBtnEmail = 'SIM';
                novoValor = 'NAO';
            }

            for (var i=0; i < this.dadosAtivacao.veiculo.configuracaoNotificacoes.length; i++) {
                this.$set(this.dadosAtivacao.veiculo.configuracaoNotificacoes[i], 'enviarEmail', novoValor);
            }
        },
        toggleBtnSMS_click() {
            let novoValor = 'SIM';
            if (this.toggleBtnSMS === 'SIM') {
                this.toggleBtnSMS = 'NÃO';            
                novoValor = 'SIM';
            } else {
                this.toggleBtnSMS = 'SIM';
                novoValor = 'NAO';
            }

            for (var i=0; i < this.dadosAtivacao.veiculo.configuracaoNotificacoes.length; i++) {
                this.$set(this.dadosAtivacao.veiculo.configuracaoNotificacoes[i], 'enviarSMS', novoValor);
            }
        },
        toggleBtnCentral_click() {
            let novoValor = 'SIM';
            if (this.toggleBtnCentral === 'SIM') {
                this.toggleBtnCentral = 'NÃO';            
                novoValor = 'SIM';
            } else {
                this.toggleBtnCentral = 'SIM';
                novoValor = 'NAO';
            }

            for (var i=0; i < this.dadosAtivacao.veiculo.configuracaoNotificacoes.length; i++) {
                this.$set(this.dadosAtivacao.veiculo.configuracaoNotificacoes[i], 'enviarCentralMonitoramento', novoValor);
            }
        },
        toggleBtnPush_click() {
            let novoValor = 'SIM';
            if (this.toggleBtnPush === 'SIM') {
                this.toggleBtnPush = 'NÃO';            
                novoValor = 'SIM';
            } else {
                this.toggleBtnPush = 'SIM';
                novoValor = 'NAO';
            }

            for (var i=0; i < this.dadosAtivacao.veiculo.configuracaoNotificacoes.length; i++) {
                this.$set(this.dadosAtivacao.veiculo.configuracaoNotificacoes[i], 'enviarPush', novoValor);
            }
        },   
        fetchListaAcessorios() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('method', 'FindAll');
            params.append('outputFormat', 'JSON');
            params.append('idVeiculo', this.dadosAtivacao.veiculo.idVeiculo);

            axios.post("/AcessorioModuloRest", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaAcessorios = response.data;                          
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaPoI - " + error); 
            })    
                                
        },
        gravarValorMensalidadeAcessorio() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('method', 'UPDATE_VALOR_INCREMENTO');
            params.append('outputFormat', 'JSON');
            params.append('idVeiculo', this.dadosAtivacao.veiculo.idVeiculo);
            params.append('mensalidade', this.popUpAcessorio.acessorio.valorIncrementoNaMensalidade);
            params.append('id', this.popUpAcessorio.acessorio.id);

            axios.post("/AcessorioModuloRest", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.fetchListaAcessorios();
                    if (response.data.hasOwnProperty('error')) {
                         this.$vs.notify({
                            title: "Erro", text: response.data.error, time: 10000,
                            iconPack: 'feather', icon: 'icon-alert-circle', color: 'warning' });
                    } else {
                        this.$vs.notify({
                            title: "Mensagem", text: response.data.mensagem, time: 10000,
                            iconPack: 'feather', icon: 'icon-alert-circle', color: 'success' });                        
                    }
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaPoI - " + error); 
            })    
                                
        },
        valorValido(valor){
           if (!valor && valor !== 0) {
               return false;
           }
            
            return true
        },
        formularioValido() {
            this.$refs.errosComponent.iniciar();
            this.errorsValidacao = [];

            if (!this.dadosAtivacao.veiculo.tipoVeiculo) { this.errorsValidacao.push("Tipo do Veículo obrigatório"); }
            if (!this.dadosAtivacao.veiculo.marca) { this.errorsValidacao.push("Marca do Veículo obrigatório"); }
            if (!this.dadosAtivacao.veiculo.modelo) { this.errorsValidacao.push("Modelo obrigatório"); }
            if (this.dadosAtivacao.veiculo.modelo === 'Outro' && !this.dadosAtivacao.veiculo.modeloOutro) {  this.errorsValidacao.push("Informe o outro modelo do veículo"); }
            if (!this.dadosAtivacao.veiculo.placa) { this.errorsValidacao.push("Placa obrigatório"); }
            if (this.dadosAtivacao.veiculo.placa && this.dadosAtivacao.veiculo.placa.length < 8) { this.errorsValidacao.push("Placa inválida"); }
            if (!this.dadosAtivacao.veiculo.corVeiculo) { this.errorsValidacao.push("Cor do Veículo obrigatório"); }
            if (!this.dadosAtivacao.veiculo.autonomia) { this.errorsValidacao.push("Autonomia obrigatório"); }
            if (!this.dadosAtivacao.veiculo.autonomiaPorHora) { this.errorsValidacao.push("Autonomia por hora obrigatório"); }
            if (!this.dadosAtivacao.veiculo.condutor) { this.errorsValidacao.push("Condutor obrigatório"); }
            if (!this.dadosAtivacao.veiculo.origemDadosOdometro) { this.errorsValidacao.push("Origem do Odômetro obrigatório"); }
            if (!this.dadosAtivacao.veiculo.origemDadosHorimetro) { this.errorsValidacao.push("Origem do Horímetro obrigatório"); }
            
            if (!this.dadosAtivacao.veiculo.tipoContratoVeiculo) { this.errorsValidacao.push("Tipo do Contrato obrigatório"); }
            if (!this.dadosAtivacao.veiculo.prazoContratoVeiculoEmMeses) { this.errorsValidacao.push("Prazo do Contrato obrigatório"); }
            if (!this.dadosAtivacao.veiculo.mensalidade) { this.errorsValidacao.push("Mensalidade obrigatório"); }
            if (this.dadosAtivacao.veiculo.marcaModuloBkp && !this.dadosAtivacao.veiculo.mensalidadeBkp) { this.errorsValidacao.push("Mensalidade de Backup obrigatório"); }
            if (!this.dadosAtivacao.veiculo.emailAvisoExcessoVelocidade) { this.errorsValidacao.push("Email 1 obrigatório"); }
            if (this.dadosAtivacao.veiculo.possuiBotaoPanico) {
                if (!this.dadosAtivacao.veiculo.procedimentoPanico) { this.errorsValidacao.push("Procedimento de Pânico obrigatório"); }
                if (!this.dadosAtivacao.veiculo.nome1ContatoPanico) { this.errorsValidacao.push("Nome do Contato 1 obrigatório"); }
                if (!this.dadosAtivacao.veiculo.senha1ContatoPanico) { this.errorsValidacao.push("Senha do Contato 1 obrigatório"); }
                if (!this.dadosAtivacao.veiculo.telefone1ContatoPanico) { this.errorsValidacao.push("Telefone do Contato 1 obrigatório"); }
            }

            if (this.dadosAtivacao.veiculo.tempoSemConexaoIgnicaoONParaAlerta) {
                if (!this.dadosAtivacao.veiculo.tempoSemConexaoParaAlerta) {
                    this.errorsValidacao.push("Informe o Alerta Sem Conexão.");
                } else if (this.dadosAtivacao.veiculo.tempoSemConexaoIgnicaoONParaAlerta > this.dadosAtivacao.veiculo.tempoSemConexaoParaAlerta) {
                    this.errorsValidacao.push("Informe o Alerta Sem Conexão maior que o Alerta Sem Conexão Ligado.");
                }
            }

            if (!this.errorsValidacao.length) {
                return true;
            }   
        
            return false;
        },
        alterarNotificaoTodosVeiculos() {        
            this.$vs.dialog({
                type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja alterar a notificações de todos veículo ? ', 
                acceptText: 'Sim', cancelText: 'Não', accept: this.alterarNotificaoTodosVeiculosRequest
            });
        },
        alterarNotificaoTodosVeiculosRequest() {
            this.$vs.loading();  
            const params = new URLSearchParams();  
            params.append('outputFormat', 'JSON');
            params.append('idCliente', this.dadosAtivacao.clienteSelecionado.id);   
            params.append('cnpjCPF', this.dadosAtivacao.clienteSelecionado.CNPJCPF);   
            params.append('acao', 'ALTERAR_NOTIFICACAO_TODOS_VEICULOS');            
            params.append('JSONItems',  JSON.stringify(this.dadosAtivacao.veiculo.configuracaoNotificacoes));

            axios.post("/AlterarVeiculo", params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.$vs.loading.close();
                    this.$vs.notify({
                            title: "Mensagem", text: response.data.mensagem, time: 10000,
                            iconPack: 'feather', icon: 'icon-alert-circle', color: 'success' });
                    this.$refs.fwAtivacao.reset();
                    this.dadosAtivacao.veiculo = { tipoVeiculo: null, marca: null, modelo:null, modeloOutro: null, placa: null, condutor: null, 
                                    autonomia: null, autonomiaPorHora: null, mensalidade: null, tipoContratoVeiculo: null, prazoContratoVeiculoEmMeses: null, possuiBotaoPanico: false,
                                    possuiBloqueio: false, centralMonitoramento: false, procedimentoPanico: null, nome1ContatoPanico: null, telefone1ContatoPanico: null,
                                    nome2ContatoPanico: null, telefone2ContatoPanico: null, nome3ContatoPanico: null, telefone3ContatoPanico: null, emailAvisoExcessoVelocidade: null, 
                                    emailAvisoSaidaCerca: null, manterMotoristaVeiculoDesligado:false, nomeContatoResponsavel1: null,  nomeContatoResponsavel2: null, anoModelo: null, anoFabricacao: null, labelVeiculo: null };
                    this.veiculoSelecionado = null;
                    this.fetchListaVeiculos();
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();                
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error);                 
            })  
        }
    },
    mounted() {               
        this.fetchListaClientes();
        this.fetchListaMarcaVeiculos();
        this.fetchListaModeloVeiculos();
    }    
}
</script>

<style>  
  .style-chooser .vs__dropdown-menu {
    max-height: 150px;
  }
</style>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 410px);
      position: relative;      
   } 
   .tabelaNotificacoesComScroll {      
      overflow: auto;  
      height: calc(100vh - 410px);
      position: relative;      
  }
  .imgHolder {
        display:inline-block;    
        width: 210px;
        height: 140px;
        margin-right: 10px;
    }

    .imgHolder img{
        display:block;
        margin:auto;
    }

    .imgHolder span {
        display: inline-block;
        border: 0.1em solid rgba(0, 0, 0, .1);
        -webkit-background-clip: padding-box; /* for Safari */
        background-clip: padding-box;    
        font-size: 0.7rem;
        text-align: center;
        width: 140px;    
    }
</style>